<template>
  <a-card :bordered="false" class="card-area">
    <!-- 搜索区域 -->
    <a-form layout="horizontal">
      <span class="fold">
        <a-row>
          <a-col :md="6" :sm="6">
             <a-form-item
                 label="下单人"
                 :labelCol="{span: 4}"
                 :wrapperCol="{span: 12, offset: 1}">
               <a-input v-model.trim="queryParams.username"/>
             </a-form-item>
          </a-col>
          <a-col :md="6" :sm="6">
             <a-form-item
                 label="城市"
                 :labelCol="{span: 4}"
                 :wrapperCol="{span: 12, offset: 1}">
               <a-input v-model.trim="queryParams.city"/>
             </a-form-item>
          </a-col>
            <a-col :md="6" :sm="6">
             <a-form-item
                 label="期望行业"
                 :labelCol="{span: 4}"
                 :wrapperCol="{span: 12, offset: 1}">
               <a-input v-model.trim="queryParams.industryName"/>
             </a-form-item>
          </a-col>
        </a-row>
      </span>
      <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="searchTable()" :loading="loading">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </span>
    </a-form>
    <a-table :dataSource="tableData" rowKey="id" size="small" :bordered="true" :columns="columns"
             :scroll="{ x: 1000 }">
      <template slot="jobtype" slot-scope="text,record">
        <a-tag :color="record.jobtype | dictName('jobtype', 'color')">
          {{ record.jobtype | dictName("jobtype") }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" @click="initiateRefund(record)">相关订单</a-button>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import {DICT} from "@/utils/dict";
import {message} from 'ant-design-vue';

export default {
  name: "jobIntro",
  data() {
    return {
      tableData: [],
      value: '',
      loading: false,
      queryParams: {
        username: '',
        city: '',
        industry: undefined
      }
    };
  },
  created() {
    this.searchTable();
  },

  computed: {
    jobtype() {
      return DICT['jobtype']
    },
    columns() {
      return [{
        title: "ID",
        width: 50,
        align: "center",
        dataIndex: "id",
      }, {
        title: "下单人",
        width: 80,
        align: "center",
        dataIndex: "username",
      }, {
        title: "城市",
        width: 80,
        align: "center",
        dataIndex: "city",
      }, {
        title: "悬赏金额",
        width: 80,
        align: "center",
        dataIndex: "payment",
      }, {
        title: "工作类型",
        width: 80,
        align: "center",
        dataIndex: "jobtype",
        scopedSlots: {customRender: "jobtype"}
      }, {
        title: "最低期望薪资",
        width: 80,
        align: "center",
        dataIndex: "minsalary",
      }, {
        title: "期望行业",
        width: 80,
        align: "center",
        dataIndex: "industryName",
      }, {
        title: "简要说明",
        width: 80,
        align: "center",
        dataIndex: "title",
      }, {
        title: "详细说明",
        width: 80,
        align: "center",
        dataIndex: "mark",
      }, {
        title: "截止时间",
        width: 80,
        align: "center",
        dataIndex: "endtime",
      }, {
        title: "发单时间",
        width: 80,
        align: "center",
        dataIndex: "createdate",
      }, {
        title: '操作',
        width: 160,
        align: 'center',
        dataIndex: 'operation',
        scopedSlots: {customRender: 'operation'}
      }];
    }
  },
  methods: {
    initiateRefund(row) {
      message.info("没有完成相关功能")
      console.log(row)
    },

    handleDateChange(value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    },
    reset() {
      this.queryParams = {}
    },

    searchTable() {
      let that = this;
      that.loading = true;
      that.$postJson("jobIntro/jobIntro", this.queryParams).then((res) => {
        that.loading = false;
        that.tableData = res.data;
      });
      this.queryParams.id = undefined;
    }
  }
}
</script>
<style>
.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
